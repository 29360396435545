body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 1040; /* Adjust as needed */
}

.wiznav {
  margin-bottom: 15px;
  text-align: center;
}

.wizdot {
  color: black;
  cursor: pointer;
  font-size: 36px;
  line-height: 1;
  margin: 0 15px;
  opacity: 0.4;
  text-shadow: none;
  transition: opacity 1s ease, text-shadow 1s ease;
  will-change: opacity, text-shadow;
}

.wizactive {
  color: blue;
  opacity: 1;
  text-shadow: 0 0px 8px;
}
.btn-primary {
  color: white !important;
  background-color: rgba(0, 24, 69, 1) !important;
  border-color: rgba(0, 24, 69, 1) !important;
}
.btn-primary:hover {
  color: white;
  background-color: #002d80 !important;
  border-color: #002d80 !important;
}

.cluster-icon {
  background-color: #333;
  height: 2em;
  width: 2em;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 1.2rem;
  box-shadow: 0 0 0px 5px #fff;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(91, 194, 194, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #212529;
  background-color: #7cc;
  border-color: #5bc2c2;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #212529;
  background-color: #9cdada;
  border-color: #2e7c7c;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(91, 194, 194, 0.5);
}

.btn-outline-primary {
  color: #7cc;
  background-color: transparent;
  background-image: none;
  border-color: #7cc;
}

.btn-outline-primary:hover {
  color: #222;
  background-color: #8ad3d3;
  border-color: #7cc;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(119, 204, 204, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #7cc;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #212529;
  background-color: #8ad3d3;
  border-color: #7cc;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(119, 204, 204, 0.5);
}

.home-page-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-page-container1 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-page-frame {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}

.nav-color {
  background-color: rgba(0, 24, 69, 1) !important;
  color: white !important;
}

.dropdown-menu {
  background-color: rgba(0, 24, 69, 1) !important;
}
.home-page-header {
  display: flex;
  padding: 16px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: rgba(0, 24, 69, 1);
}
.home-page-frame1 {
  gap: 16px;
  width: 418px;
  display: flex;
  align-items: center;
}
.home-page-image {
  width: 85px;
  height: 56px;
  object-fit: cover;
}
.home-page-frame2 {
  width: 267px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.home-page-frame-margin {
  width: 213px;
  height: 37px;
  display: flex;
  padding: 0 0 0 16px;
  align-items: flex-start;
}
.home-page-heading {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
}
.home-page-text {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 30px;
  font-style: Bold;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  line-height: 36px;
  font-stretch: normal;
  text-decoration: none;
}

.home-page-navigation {
  gap: 32px;
  width: 544px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: flex-end;
}
.home-page-link {
  width: 81px;
  height: 40px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
}
.home-page-thq-dropdown {
  color: rgba(255, 255, 255, 1);
  height: auto;
  z-index: 1000;
  position: relative;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.home-page-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-page-text02 {
  color: rgb(255, 255, 255);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: "Montserrat";
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.home-page-dropdown-arrow {
  transition: 0.3s;
}
.home-page-icon {
  fill: #ffffff;
  width: 18px;
  height: 18px;
  transition: 0.3s;
}
.home-page-dropdown-list {
  width: 218px;
  display: none;
  z-index: 1000;
  min-width: var(--dl-size-size-medium);
  transition: 0.3s;
  align-items: stretch;
  border-color: #d9d9d9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.home-page-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-page-dropdown-toggle1 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-page-dropdown-toggle1:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-page-text03 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-page-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-page-dropdown-toggle2 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
.home-page-dropdown-toggle2:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-page-text04 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-page-link1 {
  width: 69.6406021118164px;
  height: 41px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
}
.home-page-thq-dropdown1 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  z-index: 1000;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.home-page-dropdown-toggle3 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-page-text05 {
  color: rgb(255, 255, 255);
  height: auto;
  flex-grow: 1;
  font-size: 14px;
  font-style: normal;
  text-align: left;
  font-family: "Montserrat";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.home-page-dropdown-arrow1 {
  transition: 0.3s;
}
.home-page-icon2 {
  fill: #ffffff;
  width: 18px;
  height: 18px;
  transition: 0.3s;
}
.home-page-dropdown-list1 {
  width: 200px;
  display: none;
  z-index: 1000;
  min-width: var(--dl-size-size-medium);
  transition: 0.3s;
  align-items: stretch;
  border-color: #d9d9d9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.home-page-dropdown2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-page-dropdown-toggle4 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-page-dropdown-toggle4:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-page-navlink {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
}
.home-page-dropdown3 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-page-dropdown-toggle5 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-page-dropdown-toggle5:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-page-navlink1 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
}
.home-page-dropdown4 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-page-dropdown-toggle6 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-page-dropdown-toggle6:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-page-text06 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-page-link2 {
  width: 26.03125px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
}
.home-page-button {
  gap: 8px;
  width: 103.4219970703125px;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  justify-content: center;
  text-decoration: none;
  background-color: rgba(0, 24, 69, 1);
}
.home-page-sign-in-icon {
  color: #ffffff;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
}
.home-page-icon4 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.home-page-text07 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
}
.home-page-text08 {
  color: #ffffff;
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: center;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.home-page-frame3 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.home-page-sidebar {
  width: 256px;
  height: 832px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-color: rgba(240, 240, 240, 1);
}
.home-page-frame4 {
  display: flex;
  padding: 16px;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.home-page-frame-margin1 {
  display: flex;
  padding: 0 0 32px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.home-page-frame5 {
  gap: 16px;
  width: 224px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.home-page-text10 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.home-page-text11 {
  color: rgba(0, 51, 102, 1);
  height: auto;
  flex-grow: 1;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.home-page-frame6 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.home-page-label {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.home-page-text13 {
  color: rgba(51, 51, 51, 1);
  height: auto;
  flex-grow: 1;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 20px;
  font-stretch: normal;
  text-decoration: none;
}
.home-page-field-a {
  display: flex;
  padding: 8px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(51, 51, 51, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
}
.home-page-text15 {
  color: rgba(156, 163, 175, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.home-page-label1 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.home-page-text16 {
  color: rgba(51, 51, 51, 1);
  height: auto;
  flex-grow: 1;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 20px;
  font-stretch: normal;
  text-decoration: none;
}
.home-page-field-b {
  display: flex;
  padding: 8px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(51, 51, 51, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
}
.home-page-text18 {
  color: rgba(156, 163, 175, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.home-page-frame7 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.home-page-text19 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.home-page-navigation1 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.home-page-image1 {
  width: 256px;
  height: 88px;
  object-fit: cover;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-page-container2 {
  flex: 0 0 auto;
  width: 1115px;
  height: 835px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-page-pasted-image {
  width: 1208px;
  height: 837px;
  z-index: 1;
}
.home-page-footer {
  display: flex;
  padding: 16px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: rgba(0, 24, 69, 1);
}
.home-page-frame8 {
  gap: 16px;
  display: flex;
  flex-grow: 1;
  align-items: center;
}
.home-page-text20 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
}
.home-page-text21 {
  color: rgba(255, 255, 255, 1);
  width: 244px;
  height: auto;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.home-page-navigation2 {
  gap: 32px;
  display: flex;
  flex-grow: 1;
  align-items: center;
}
.home-page-link3 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
}
.home-page-text23 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.home-page-link4 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
}
.home-page-text25 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.home-page-link5 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
}
.home-page-text27 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  flex-grow: 1;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}

[data-thq="thq-dropdown"]:hover > [data-thq="thq-dropdown-list"] {
  display: flex;
}

[data-thq="thq-dropdown"]:hover > div [data-thq="thq-dropdown-arrow"] {
  transform: rotate(90deg);
}

.leaflet-container {
  height: 1200px;

  max-width: 2000px;
  max-height: 1200px;
}

.select2-selection__rendered {
  line-height: 22px !important;
}

.select2-container .select2-selection--single {
  height: 38px !important;
}

.select2-container .select2-selection--multiple {
  height: 38px !important;
  min-height: 38px;
}

.select2-selection__choice {
  height: 27px;
  padding-top: -1px;
}

.select2-container .select2-selection--multiple {
  height: auto !important;
  margin: 0;
  padding: 0;
  line-height: inherit;
}

.select2-container .select2-search--inline .select2-search__field {
  margin: 0;
  padding: 0;
  min-height: 0;
}

.ctr {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 20px;
  margin-right: 20px;
  width: 100%;
}

.left-column {
  flex-basis: 20% !important;
  padding-right: 10px;
}

.right-column {
  flex-basis: 80% !important;
}

.sidebar {
  flex-basis: 25%;
  background-color: #f2f2f2;
  padding: 20px;
  overflow-y: auto;
}

.sidebar h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

.item {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.item:hover,
.item.active {
  background-color: #e6e6e6;
}

.item h3 {
  margin-bottom: 5px;
  font-size: 18px;
}

.item p {
  color: #999;
  font-size: 14px;
}

.sidebar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.topbar {
  background-color: #001844;
  color: white;
  width: 100%;
  padding: 4px;
  margin-bottom: 5px;
  top: 0;
  left: 0;
  height: 48px;
  z-index: 1;
}
